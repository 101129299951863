<template>
  <div class="w-full min-h-screen bg-eliteverde">
    <Menu />
    <Hero />
    <Resumen />
    <AreasHandler :mostrarFooter="false" />
    <BlogMuestra v-if="hayPosts" />
    <NuestrosClientes />
    <Contacto />
    <Footer />
  </div>
</template>

<script>
import Menu from "./Menu";
import Hero from "./Hero";
import AreasHandler from "./AreasHandler";
import Contacto from "./Contacto";
import BlogMuestra from "./BlogMuestra";
import Resumen from "./Resumen";
import Footer from "./Footer";
//import Nosotros from './Nosotros'
//import MisionVision from "./MisionVision";
import Api from "@/components/Api";
import NuestrosClientes from "./NuestrosClientes.vue";
export default {
  name: "HelloWorld",
  components: {
    Menu,
    Hero,
    AreasHandler,
    Resumen,
    BlogMuestra,
    //Nosotros,
    Contacto,
    Footer,
    NuestrosClientes,
  },
  props: ["empresa"],
  mounted() {
    Api.$on("hayPosts", this.sethayPosts);
  },
  methods: {
    sethayPosts(hayPosts) {
      this.hayPosts = hayPosts;
    },
  },
  data() {
    return {
      lang: localStorage.getItem("lang") || "es",
      hayPosts: false,
      nombre: "ELITE Abogados",
      botonVerMas: {
        es: "Ver más",
        en: "More",
      },
      nosotrosTitulo: {
        es: "Lo que nos mueve son nuestros clientes",
        en: "Our clients are what moves us",
      },
      nosotrosSubtitulo: {
        es: "Somos una firma de abogados con más de 35 años de experiencia en diferentes ramas del derecho. <br /><br />Nuestra filosofía es prestar servicios profesionales e impecables para generar soluciones y alternativas confiables para nuestros amplio sector de clientes y colaboradores todo ello apegado a las normas de nuestra ética profesional. <br /><br />Actualmente contamos con dos despachos, uno en la ciudad de San Luis Potosí y otro en la Ciudad de México en los que se atiende a clientes mexicanos y extranjeros. ",
        en: "We are a law firm with over 35 years of experience in multiple law areas. <br /><br />Our philosophy is to render professional, impecable services in order to provide trustworthy alternatives and solutions for our wide range of clients and partners, all of that aligned with our ethics normative. <br /><br />We have currently two branch offices, one in San Luis Potosi, and another in Mexico City, both of them attending local and foreign clients. ",
      },
      tituloMision: {
        es: "Nuestra Misión",
        en: "Our Mission",
      },
      tituloVision: {
        es: "Nuestra Visión",
        en: "Our Vision",
      },
      tituloValores: {
        es: "Nuetros valores",
        en: "Our values",
      },
      mision: {
        es: "Nuestra misión es prestar servicios profesionales en las diferentes ramas del derecho, de manera responsable, honesta, eficiente, y con un alto grado de prestigio, equidad y justicia, generando soluciones y alternativas confiables para nuestros clientes. <br /><br />Buscamos mantenernos al día en el conocimiento jurídico y formación continua de nuestras especialidades para poder responder de manera eficiente a nuestros clientes, así como, lograr la realización personal y profesional de nuestros miembros, dentro de los más altos estándares éticos y de excelencia.",
        en: "Our mission is to render professional services in multiple law areas, being responsible, honest and efficient, and with the highest grade of prestige, equity and justice, providing trustworthy solutions and alternatives to our customers. <br /><br />We are looking for keeping up to date in legal knowledge and skills in all our specialties in order to respond efficiently to our clients, and in order to achieve professional and personal realization of our firm members, among the hightest excelence and ethics standards.",
      },
      vision: {
        es: "Aspiramos a ser una de las firmas de mayor transcendencia jurídica y social, generando para nuestros clientes, socios y colaboradores, un crecimiento profesional y así ser reconocidos por nuestros altos estándares de calidad y profesionalidad.",
        en: "We aim to be one of the greatest legal and social transcendence, allowing professional growth to our clients, associates and partners, thus being recognized for our high quality standards.",
      },
      valores: {
        es: "Nuestra filosofía es prestar servicios profesionales e impecables regidos por la honestidad, la lealtad y con la certeza de ofrecer estrategias que brindan una solución efectiva a las necesidades legales, todo ello, apegado a las normas de nuestra ética profesional.<br /><br />El equipo de Bufete de la Garza es reconocido por la calidad y la integridad que se ha brindado de generación a generación así como por su prestigio y el de sus clientes. <br /><br />Nuestra organización está plenamente consciente y comprometida con la sociedad, es así, que como parte de nuestra responsabilidad social participamos activamente con la fundación 'Renace', la cual tiene como objetivo defender los derechos humanos de personas en situación de pobreza a través de representación jurídica, tratamiento psicológico y bolsa de trabajo.<br /><br />Nos mantenemos en constante innovación tanto en las soluciones legales como en la infraestructura y tecnología, optimizando nuestros recursos necesarios para proporcionar a nuestros clientes un servicio de calidad y a la vanguardia, contando con aplicaciones y herramientas dinámicas(Software de gestión de casos y horas, base de datos jurídica, big data e Inteligencia artificial y telecomunicaciones). ",
        en: "Nuestra filosofía es prestar servicios profesionales e impecables regidos por la honestidad, la lealtad y con la certeza de ofrecer estrategias que brindan una solución efectiva a las necesidades legales, todo ello, apegado a las normas de nuestra ética profesional.<br /><br />El equipo de Bufete de la Garza es reconocido por la calidad y la integridad que se ha brindado de generación a generación así como por su prestigio y el de sus clientes. <br /><br />Nuestra organización está plenamente consciente y comprometida con la sociedad, es así, que como parte de nuestra responsabilidad social participamos activamente con la fundación 'Renace', la cual tiene como objetivo defender los derechos humanos de personas en situación de pobreza a través de representación jurídica, tratamiento psicológico y bolsa de trabajo.<br /><br />Nos mantenemos en constante innovación tanto en las soluciones legales como en la infraestructura y tecnología, optimizando nuestros recursos necesarios para proporcionar a nuestros clientes un servicio de calidad y a la vanguardia, contando con aplicaciones y herramientas dinámicas(Software de gestión de casos y horas, base de datos jurídica, big data e Inteligencia artificial y telecomunicaciones). ",
      },
    };
  },
};
</script>
