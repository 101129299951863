<template>
  <section
    class="w-screen grid grid-cols-1 place-content-center text-serif"
    :class="separadores ? 'py-40 border-y-8 border-verde' : 'py-36'"
    id="contacto"
  >
    <div class="container mx-auto text-verde">
      <h1 class="text-xl pb-6 px-6" v-if="lang == 'es'">
        Gracias por su interés en FIRMA DE ABOGADOS ÉLITE, para poder apoyarlo,
        por favor contáctenos:
      </h1>
      <h1 class="text-xl pb-6 px-6" v-if="lang == 'en'">
        Thank you for your interest in FIRMA DE ABOGADOS ÉLITE, in order to
        support you, please contact us:
      </h1>
      <div
        class="
          m-4
          grid grid-cols-1
          md:grid-cols-2
          gap-8
          place-content-center
          text-verde
        "
      >
        <a href="tel:4441808193" class="text-xl p-6 no-underline"
          ><i class="fa-solid fa-phone"></i
          ><strong> +52 444 1 80 81 93</strong></a
        >
        <a
          href="mailto:contacto@elite-abogados.com.mx"
          class="text-xl p-6 no-underline"
          ><i class="fa-solid fa-envelope"></i
          ><strong> contacto@elite-abogados.com.mx</strong></a
        >
      </div>
      <h1 class="text-xl pt-6 px-6" v-if="lang == 'es'">
        Lo atenderemos con gusto en nuestro horario de oficina, de lunes a
        viernes de 9:00 a 14:00 y de 16:30 a 20:30.
      </h1>
      <h1 class="text-xl pt-6 px-6" v-if="lang == 'en'">
        We will be glad to assist you in our office hours, Monday through Friday
        from 9:00 to 14:00 and from 16:30 to 20:30.
      </h1>
    </div>
  </section>
</template>
<script>
export default {
  name: "BotonAgendaCita",
  props: {
    separadores: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      lang: localStorage.getItem("lang") || "es",
    };
  },
};
</script>
