<template>
  <section class="min-h-screen w-screen bg-white font-serif">
    <div
      class="
        grid grid-cols-1
        md:grid-cols-7
        border-x-8 border-verde
        md:border-0
      "
    >
      <div class="h-64 md:min-h-screen col-span-4">
        <!--transition-group cclass="col-span-4" name="slide-fadee"-->
        <div
          v-for="i in itemsEnGaleria"
          :key="i"
          v-show="indiceGaleria == i"
          class="h-64 md:min-h-screen place-content-center bg-cover bg-center"
          :class="'bg-[url(\'galeria/galeria%20' + i + '.jpg\')]'"
          @click="indiceGaleria++"
        ></div>
        <!--/transition-group-->
      </div>
      <div
        v-if="lang == 'es'"
        class="
          col-span-3
          md:min-h-screen
          grid grid-cols-1
          place-content-center
          text-verde
          p-8
          md:pl-8
          sm:pr-24
          md:pr-48
        "
      >
        <h1 class="text-xl">BIENVENIDO A FIRMA DE ABOGADOS ÉLITE</h1>
        <div class="md:py-2 md:text-lg">
          <p>&nbsp;</p>

          <p class="mb-4">
            Despacho jurídico especializado en las principales áreas que
            requiere el sector empresarial.
          </p>
          <p class="mb-4">
            Nos caracteriza ser una de las pocas firmas del Bajío en ofrecer un
            servicio legal integral.
          </p>
          <p class="mb-4">
            Contamos con abogados especializados y altamente capacitados.
          </p>
          <p class="mb-4">
            En Firma de Abogados Élite, generamos confianza a través de
            soluciones y resultados.
          </p>

          <p class="mt-6">
            <a
              href="#/nosotros"
              class="
                no-underline
                text-dorado
                border-2 border-dorado
                rounded-md
                p-3
              "
              >Ver más</a
            >
          </p>
        </div>
      </div>
      <div
        v-if="lang == 'en'"
        class="
          col-span-3
          md:min-h-screen
          grid grid-cols-1
          place-content-center
          text-verde
          p-8
          md:pl-8
          sm:pr-24
          md:pr-48
        "
      >
        <h1 class="text-xl">WELCOME TO FIRMA DE ABOGADOS ÉLITE</h1>
        <div class="md:py-2 md:text-lg">
          <p>&nbsp;</p>

          <p class="mb-4">
            Law firm specialized in key areas required by the corporate sector.
          </p>
          <p class="mb-4">
            We are distinguished as one of the few firms in the Bajío region to
            offer an integral legal service.
          </p>
          <p class="mb-4">We have specialized and highly trained lawyers.</p>
          <p class="mb-4">
            Firma de Abogados Élite generates trust through solutions and
            results.
          </p>

          <p class="mt-6">
            <a
              href="#/nosotros"
              class="
                no-underline
                text-dorado
                border-2 border-dorado
                rounded-md
                p-3
              "
              >See more</a
            >
          </p>
        </div>
      </div>
    </div>
  </section>
</template>
<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 1.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.sslide-fade-leave-active {
  transition: all 0.8s cubic-bezier(0, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
<script>
import Api from "@/components/Api";
export default {
  name: "Resumen",
  data() {
    return {
      empresa: Api.obtenerLocal("elite-abogados.mx.empresa") || {},
      lang: localStorage.getItem("lang") || "es",
      indiceGaleria: 1,
      itemsEnGaleria: 6,
    };
  },
  methods: {
    cambiarIndiceGaleria() {
      this.indiceGaleria =
        this.indiceGaleria == this.itemsEnGaleria ? 1 : this.indiceGaleria + 1;
    },
  },
  mounted() {
    setInterval(this.cambiarIndiceGaleria, 5000);
  },
};
</script>
