<template>
  <section class="w-screen py-24 bg-verde font-serif text-white">
    <div class="container mx-auto">
      <h1 class="text-center text-lg py-4">
        <span v-if="lang == 'es'">Algunos de nuestros clientes</span>
        <span v-if="lang == 'en'">Some of our Clientes</span>
      </h1>
      <!--div>
        <div
          id="carouselExampleCaptions"
          class="carousel slide relative"
          data-bs-ride="carousel"
        >
          <div
            class="
              carousel-indicators
              absolute
              right-0
              bottom-0
              left-0
              flex
              justify-center
              p-0
              mb-4
            "
          >
            <button
              type="button"
              v-for="(m, indice) in empresa.membresias"
              :key="m"
              data-bs-target="#carouselExampleCaptions"
              :data-bs-slide-to="indice"
              class="active"
              aria-current="true"
              :aria-label="'Slide ' + (m + 1)"
            ></button>
          </div>
          <div class="carousel-inner relative w-full overflow-hidden">
            <div
              :class="{ active: indice == 0 }"
              class="carousel-item relative float-left w-full"
              v-for="(m, indice) in empresa.membresias"
              :key="indice"
            >
              <div
                class="relative overflow-hidden bg-no-repeat bg-cover"
                style="background-position: 50%"
              >
                <div class="grid grid-cols-1 place-content-center h-96 my-auto">
                  <p class="text-center">
                    <img
                      :src="'clientes/' + m + '.png'"
                      class="membresia max-h-48 max-w-64 p-3 inline-block"
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <button
            class="
              carousel-control-prev
              absolute
              top-0
              bottom-0
              flex
              items-center
              justify-center
              p-0
              text-center
              border-0
              hover:outline-none hover:no-underline
              focus:outline-none focus:no-underline
              left-0
            "
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide="prev"
          >
            <span
              class="carousel-control-prev-icon inline-block bg-no-repeat"
              aria-hidden="true"
            ></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="
              carousel-control-next
              absolute
              top-0
              bottom-0
              flex
              items-center
              justify-center
              p-0
              text-center
              border-0
              hover:outline-none hover:no-underline
              focus:outline-none focus:no-underline
              right-0
            "
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide="next"
          >
            <span
              class="carousel-control-next-icon inline-block bg-no-repeat"
              aria-hidden="true"
            ></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div-->
      <div
        class="
          grid grid-cols-1
          lg:grid-cols-4
          xl:grid-cols-7
          gap-4
          place-content-center
        "
      >
        <div
          class="grid grid-cols-1 place-content-center"
          v-for="m in empresa.membresias"
          :key="m"
        >
          <p class="text-center">
            <img
              :src="'clientes/' + m + '.png'"
              class="membresia max-h-32 p-3 inline-block max-w-1/2"
            />
          </p>
        </div>
      </div>
    </div>
  </section>
</template>
<style scoped>
.membresia {
  /*filter: invert(100%);*/
}
</style>
<script>
export default {
  name: "Membresias",
  data() {
    return {
      lang: localStorage.getItem("lang") || "es",
      empresa: {
        membresias: [
          "seguroselpotosi",
          "hems",
          "stevens",
          "firase",
          "hospitallomas",
          "parroquia",
          "radiopanoramica",
        ],
      },
    };
  },
  mounted() {
    console.log("Membresias", this.empresa.membresias);
  },
};
</script>
