<template>
  <section
    class="
      h-auto
      w-screen
      py-24
      bg-stone-200 bg-cover bg-center bg-[url('g858.png')]
    "
  >
    <h1 class="text-center text-3xl uppercase">
      {{ lang == "es" ? "publicaciones" : "publications" }}
    </h1>
    <p class="text-center">
      &nbsp;
      <span class="border-b-2 border-verde"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
      >
    </p>
    <p class="text-center">&nbsp;</p>
    <div
      class="
        container
        mx-auto
        grid grid-cols-1
        md:grid-cols-3
        gap-6
        place-content-center
      "
    >
      <a
        v-for="p in posts"
        :key="p._id"
        class="text-center bg-stone-100 no-underline text-verde"
        :href="p.slug ? '#/post/' + p.slug : '#/blog/' + p._id"
      >
        <div
          :class="
            'h-48 w-auto bg-cover bg-center bg-[url(\'' +
            cdn +
            p.imagen +
            '\')]'
          "
        ></div>
        <div class="p-6">
          <h1 class="pt-2 text-2xl uppercase">{{ p.titulo }}</h1>
        </div>
        <div class="p-6 bg-white">
          <p class="text-left">{{ p.contenido.substring(0, 150) }}...</p>
          <p class="text-right">
            <span class="no-underline text-dorado">Ver más</span>
          </p>
        </div>
      </a>
    </div>
  </section>
</template>
<script>
import Api from "@/components/Api";
export default {
  name: "BlogMuestra",
  data() {
    return {
      cdn: Api.cdn,
      posts: [],
      lang: localStorage.getItem("lang") || "es",
    };
  },
  async mounted() {
    this.posts = (await Api.readBypass("postpublic"))
      .sort((a, b) => (a.fechaPublicado > b.fechaPublicado ? 1 : -1))
      .slice(0, 3); //*/
  },
};
</script>
