<template>
  <div>
    <BotonAgendaCita :lang="lang" />
    <section class="w-full bg-dorado">
      <div class="container mx-auto">
        <div class="grid grid-cols-1 md:grid-cols-2">
          <div class="text-center md:text-right p-16">
            <img src="1x/telefonoinstagram.png" class="inline-block" />
          </div>
          <div
            class="
              text-center
              md:text-left md:p-16
              grid grid-cols-1
              place-content-center
            "
          >
            <div class="grid grid-cols-2 place-content-center">
              <a
                v-for="r in redes"
                :key="r.link"
                :href="r.link"
                class="text-white no-underline m-8 text-center"
                target="_blank"
              >
                <i class="text-2xl" :class="r.icono"></i>
                <p class="">{{ r.label[lang] || r.label }}</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import BotonAgendaCita from "./BotonAgendaCita";
export default {
  name: "Contacto",
  components: { BotonAgendaCita },
  data() {
    return {
      lang: localStorage.getItem("lang") || "es",
      redes: [
        {
          label: { es: "Llámenos", en: "Call us" },
          icono: "fa-solid fa-phone",
          link: "tel:4441809193",
        },
        {
          label: "Email",
          icono: "fa-solid fa-solid fa-envelope",
          link: "mailto:contacto@elite-abogados.mx",
        },
        {
          label: "Instagram",
          icono: "fa-brands fa-instagram",
          link: "https://www.instagram.com/firmadeabogadoselite",
        },
        {
          label: "LinkedIn",
          icono: "fa-brands fa-linkedin",
          link: "https://www.linkedin.com/company/elite-firma-de-abogados",
        },
      ],
    };
  },
};
</script>
